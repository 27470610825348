$page-background: #FFFBFE;

$main-column-min-width: 360px;
$main-column-max-width: 1440px;

$color-gray: #79747E;

$text-color-main: #1C1B1F;
$text-color-dark: #1D192B;
$text-color-white: #FFFFFF;
$text-color-white-second: #F4EFF4;
$text-color-gray: #49454F;
$text-color-gray-light: #958DA5;
$text-color-green: #386B01;
$text-color-green-light: #82BB50;
$text-color-crimson:#900037;
$text-color-dark-blue:#0D47A1;
$text-color-blue:#1257B2;
$text-color-purple:#673AB7;
$text-color-burgundy:#410E0B;
$text-color-burgundy-second: #400014;
$text-color-pink-light: #E7E0EC;

$text-color-error: #B3261E;
$text-color-error-second: #F9DEDC;
$text-color-success: #9CD769;

$background-color-main:#1C1B1F;
$background-color-black:#313033;
$background-color-gray:#CAC4D0;
$background-color-pink: #FCEEEE;
$background-color-violet: #F6EDFF;
$background-color-cream: #FFECEE;
$background-color-error:#F2B8B5;
$background-color-crimson:#900037;
$background-color-blue:#1257B2;
$background-color-red: #8C1D18;
$background-color-purple: #673AB7;

$breakpoints: (
  mobile: unquote("(max-width: 400px)"),
  tablet: unquote("(min-width: 401px) and (max-width: 834px)"),
  desktop: unquote("(min-width: 835px)"),
);
